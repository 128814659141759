import { WifiCommandBody } from './../types/wifi';
import store from 'store';
import { WifiState } from 'types/wifi';
import axios, { AxiosRequestConfig } from 'axios'

// const URL = window.location.hostname === "localhost" ? "http://localhost:8000/wlan" : "https://scanapi.ullstar.de/wlan"
const URL =  "https://scanapi.ullstar.de/wlan"


const authHeader = ():AxiosRequestConfig | null => {
  const token = store.getState()?.user?.token

  if (!token) {
    console.log('no token!')
    return null
  }
  return {
    headers: { Authorization: `Bearer ${token}` }
  };
}

export function get(): Promise<WifiState> | null {
  const config = authHeader()
  if (!config) {
    return null
  }

  const p = axios.get(URL, config)
    .then((res) => res.data)
    .catch( e => { if (e?.response?.status == 401) {throw e} else { console.log("API could not be fetched", e)} } )
  return p
}

export function post(body: WifiCommandBody): Promise<WifiState> | null {
  const config = authHeader()
  return config ? axios.post(URL, body, config).then((res) => res.data) : null
}
