import React from 'react'
import { Container } from 'components/shared'
import { Route, Switch, Redirect } from 'react-router-dom'
import useStyles from './styles'

import SignInContainer from 'components/pages/SignIn'
import routes from 'routing/routes'
import { BUILD_VERSION } from 'utils/version'

const UnsignedLayout = () => {
  const classes = useStyles()

  return (
    <Container maxWidth="xs">
      <Switch>
        <Route exact path={routes.unsigned.signin} component={SignInContainer} />
        <Redirect to={routes.unsigned.signin} />
      </Switch>
      <div className={classes.versionWrapper}>
        <div className={classes.version}>
          version {BUILD_VERSION}
        </div>
      </div>
    </Container>
  )
}

export default UnsignedLayout
