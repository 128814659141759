import { grey } from '@material-ui/core/colors'
import {makeStyles} from '@material-ui/core/styles'

export default makeStyles((_) => ({
  versionWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  version: {
    color: grey[600],
  },
}))
