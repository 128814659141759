import {UserAPIType} from 'types/user'
import {SetActionType} from './types'

export const USERINFO_SET = 'USERINFO:SET'

export function set(user: UserAPIType): SetActionType {
  return {
    type: USERINFO_SET,
    user
  }
}
