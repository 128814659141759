import React, { useEffect, useState } from 'react'
import WifiContent from './Content'
import * as wifiApi from 'api/wifis'
import { WifiState } from 'types/wifi'
import { useDispatch } from 'store'
import * as userActions from 'store/user/actions'

const WifiContainer = () => {
  const [wifis, setWifis] = useState<WifiState | null>(null)
  const dispatch = useDispatch()

  useEffect(() => {
    const update = () => {
      wifiApi.get()?.then((res) => {
        setWifis(res)
      }).catch(() => dispatch(userActions.remove())
      )
    }

    update()
    const interval = setInterval(() => {
      update()
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  return <WifiContent wifis={wifis} />
}
export default WifiContainer
