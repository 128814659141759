import React from 'react'
import { Router, Redirect, Switch } from 'react-router-dom'
import { MuiThemeProvider } from '@material-ui/core/styles'

import { CssBaseline } from 'components/shared'
import Route from 'components/other/Route'
import DashboardLayout from 'components/layout/DashboardLayout'
import UnsignedLayout from 'components/layout/UnsignedLayout'
import history from 'routing/routerHistory'
import routes from 'routing/routes'
import { useDispatch } from 'store'
import * as userActions from 'store/user/actions'
import * as userStorage from 'utils/userStorage'
import theme from 'utils/theme'
import { get as getUser } from 'api/user'
import * as userInfoActions from 'store/userInfo/actions'

import useStyles from './styles'

const signedPaths = Object.values(routes.signed)
const unsignedPaths = Object.values(routes.unsigned)

const Root = () => {
  // Inject @global styles
  useStyles()

  const dispatch = useDispatch()
  const user = userStorage.get()
  if (user) {
    dispatch(userActions.set(user))
    getUser(user.token)?.then(userData => {
      console.log("User data loaded: ", userData)
      dispatch(userInfoActions.set(userData))
      console.log("dispatch done")
    }).catch(e => {
      console.log("User data loading failed: ", e)
    })

  }

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Router history={history}>
        <Switch>
          <Route requiresAuth path={signedPaths} component={DashboardLayout} />
          <Route inaccessibleWithToken path={unsignedPaths} component={UnsignedLayout} />
          <Redirect to={routes.signed.wifi} />
        </Switch>
      </Router>
    </MuiThemeProvider>
  )
}

export default Root
