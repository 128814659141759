import React, { useState, useEffect } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import AppBar from './AppBar'
import SideBar from './SideBar'
import routes from 'routing/routes'
import theme from 'utils/theme'

import useStyles from './styles'
import WifiContainer from 'components/pages/Wifi'

const DashboardLayout = () => {
  const classes = useStyles()
  const [sidebarMobileOpen, setMobileSidebarOpen] = useState(false)

  function toggleMobileSidebar() {
    setMobileSidebarOpen(!sidebarMobileOpen)
  }

  function onWindowResize() {
    if (window.innerWidth >= theme.breakpoints.values.md) {
      setMobileSidebarOpen(false)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', onWindowResize)
    return () => window.removeEventListener('resize', onWindowResize)
  }, [])

  return (
    <>
      <div className={classes.wrapper}>
        <AppBar toggleMobileSidebar={toggleMobileSidebar} />
        <SideBar mobileOpen={sidebarMobileOpen} toggleMobileSidebar={toggleMobileSidebar} />
        <Switch>
          <Route exact path={routes.signed.wifi} component={WifiContainer} />
          <Redirect to={routes.signed.wifi} />
        </Switch>
      </div>
    </>
  )
}

export default DashboardLayout
