import React from 'react'

import SignInContent from './Content'
import * as userActions from 'store/user/actions'
import * as userInfoActions from 'store/userInfo/actions'
import * as userStorage from 'utils/userStorage'
import { useDispatch } from 'store'
import { TOKEN_URL } from 'api/login'
import { get as getUser } from 'api/user'

const SignInContainer = () => {
  const dispatch = useDispatch()

  const submitLogin = async (email: string, password: string) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: JSON.stringify(
        `grant_type=&username=${email}&password=${password}&scope=&client_id=&client_secret=`
      ),
    };

    const response = await fetch(TOKEN_URL, requestOptions);
    const data = await response.json();

    console.log("fetch done", data);
    let token = null;

    if (!response.ok) {
      console.log("error signing in: ", data.detail);
    } else {
      console.log("OK signing in: ", data);
      token = data.access_token;
      getUser(token)?.then(userData => {
        console.log("User data loaded: ", userData)
        dispatch(userInfoActions.set(userData))
        console.log("dispatch done")
      }).catch(e => {
        console.log("User data loading failed: ", e)
      })
    }

    const authData = {
      email,
      token,
    }

    return authData
  };

  async function authenticate(email: string, password: string, remember: boolean, setFailed: (failed: boolean) => void) {
    const authData = await submitLogin(email, password)
    if (authData.token) {
      setFailed(false)
    } else {
      setFailed(true)
    }

    if (remember) {
      userStorage.save(authData)
    }

    dispatch(userActions.set(authData))
  }

  return <SignInContent authenticate={authenticate} />
}

export default SignInContainer
