import * as actions from './actions'
import {UserStateType, SetActionType} from './types'

const defaultState = {
  email: null,
  token: null,
  admin: false,
}

export default (state: UserStateType = defaultState, action: SetActionType) => {
  switch (action.type) {
    case actions.USERINFO_SET:
      return {
        ...action.user
      }
    default:
      return state
  }
}
