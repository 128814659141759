import { grey } from '@material-ui/core/colors'
import {makeStyles} from '@material-ui/core/styles'

export default makeStyles(() => ({
    versionWrapper: {
        width: '100%',
        paddingTop: "1em",
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
      },
      version: {
        color: grey[600],
      },
}))
