import React, { useState, SyntheticEvent } from 'react'
// import MuiAlert from '@mui/material/Alert';

import { Avatar, Button, Checkbox, FormControlLabel, Icon, Typography, TextField, Snackbar } from 'components/shared'
import { ChangeEventType } from 'types/events'
import { ObjectType } from 'types/object'

import useStyles from './styles'

type Props = {
  authenticate: (username: string, password: string, remember: boolean, setFailed: (failed: boolean) => void) => void,
}

const SignInContent = ({ authenticate }: Props) => {
  const classes = useStyles()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [remember, setRemember] = useState(false)
  const [failed, setFailed] = useState(false)
  const [open, setOpen] = useState(false)

  const changeMap: ObjectType = {
    username: setUsername,
    password: setPassword,
    remember: setRemember
  }

  const setLoginError = (isError = false): void => {
    setFailed(isError)
    setOpen(isError)
  }

  function changeInputState({ target: { name, value, type } }: ChangeEventType, checked?: boolean) {
    changeMap[name](type === 'checkbox' ? checked : value)
  }

  function submit(e: SyntheticEvent) {
    e.preventDefault()
    authenticate(username, password, remember, setLoginError)
  }

  return (
    <div className={classes.signInWrapper}>
      <Avatar className={classes.signInAvatar}>
        <Icon type="LockOutlined" />
      </Avatar>
      <Typography component="h1" variant="h5">
        WLAN Sign in
      </Typography>
      <form onSubmit={submit} className={classes.signInForm}>
        <TextField
          autoFocus
          required
          fullWidth
          id="username"
          name="username"
          type="username"
          variant="outlined"
          margin="normal"
          label="Username"
          autoComplete="username"
          value={username}
          onChange={changeInputState}
        />
        <TextField
          required
          fullWidth
          id="password"
          name="password"
          type="password"
          variant="outlined"
          margin="normal"
          label="Password"
          autoComplete="current-password"
          value={password}
          onChange={changeInputState}
          error={failed}
        />
        <FormControlLabel
          control={<Checkbox value={remember} name="remember" color="primary" onChange={changeInputState} />}
          label="Remember me"
        />
        <Snackbar open={open} autoHideDuration={2000} message="Login Failed" onClose={() => setOpen(false)} />

        <Button
          fullWidth
          className={classes.signInSubmit}
          type="submit"
          variant="contained"
          color="primary"
          data-testid="sign-in-button"
        >
          Sign in
        </Button>
      </form>
    </div>
  )
}

export default SignInContent
