import {  brown, grey } from '@material-ui/core/colors'
import {makeStyles} from '@material-ui/core/styles'

export default makeStyles(() => ({
  wrapper: {
    height: '100%',
    color: 'white',
  },
  avatar: {
    textTransform: 'uppercase',
    color: 'white',
    backgroundColor: brown[900],
    fontWeight: 'bolder'
  },
  headerContent: {
    overflow: 'hidden',
    textTransform: 'capitalize',
    fontWeight: 'bolder'
  },
  media: {
    paddingTop: '50%',
    height: '100%'
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    width: '100%',
  },
  wifiName: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 'larger',
    paddingBottom: 10,
  },
  separatorLine: {
    width: '90%',
    align: 'left',
  },
  iconWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '90%',

  },
  iconsLeftAligned: {
    alignSelf: 'flex-start',
  },
  iconsRightAligned: {
    alignSelf: 'flex-end',
  },
  settings: {
    alignSelf: 'right',
   },
  timeLine: {
    fontSize: 'smaller',
    color: grey[400],
  },
  bold: {
    color: 'white',
  }
}))
