import React from 'react'
import { Avatar, Card, CardHeader } from 'components/shared'
// import { Avatar, Button, Card, CardHeader, CardMedia, CardActions, Typography } from 'components/shared'
import * as wifiApi from 'api/wifis'

import useStyles from './styles'
import { Wifi } from 'types/wifi'
import { blue, green, grey, red } from '@material-ui/core/colors'
import { format_seconds } from 'utils/timeUtils'
import { Icon, IconButton } from 'components/shared'
import { useSelector } from 'store'

type Props = {
  id: string
  wifi: Wifi
}

const WifiContentEntry = ({ id, wifi }: Props) => {
  const classes = useStyles()
  const userInfo = useSelector((state) => state.userInfo)

  const body = {
    wifi_name: id,
    wifi_enable: !wifi.enabled,
    metered: true,
    user: userInfo.username,
  }

  const toggle = (metered = true) => {
    body.metered = metered
    console.log("toggling! body: ", body)
    wifiApi.post(body)?.then((res) => {
      console.log("request success", res)
      // setWifis(res)
    }).catch((e) => console.log("POST failed", e))
  }


  const addTime = () => {
    console.log("add Time")
  }

  const block = () => {
    console.log("block")
  }

  const settings = () => {
    console.log("settings")
  }

  let color: string = wifi.enabled ? wifi.metered ? blue[900] : green[900] : red[900]
  color = wifi.updating ? grey[800] : color

  if (!wifi.name) {
    return null
  }

  const meteredLine = wifi.enabled && wifi.metered ? `${classes.bold} ${classes.timeLine}` : classes.timeLine
  const unmeteredLine = wifi.enabled && !wifi.metered ? `${classes.bold} ${classes.timeLine}` : classes.timeLine

  console.log(wifi)
  return (
    <Card className={classes.wrapper} component="section">
      <CardHeader
        classes={{
          content: classes.headerContent
        }}
        style={{ backgroundColor: color }}
        avatar={<Avatar className={classes.avatar}>{wifi.name[0]}</Avatar>}
        title={
          <div className={classes.cardContent}>
            <div className={classes.wifiName}>
              {wifi.name}
            </div>
            {/* remaining: {format_seconds(wifi.periods[0].remaining)} */}
            <div className={meteredLine}>Time this week: {format_seconds(wifi.periods[0].metered)} {wifi.enabled && wifi.metered && <span> ⇦</span>} </div>
            <div className={unmeteredLine}> Unlimited time: {format_seconds(wifi.periods[0].unmetered)} {wifi.enabled && !wifi.metered && <span> ⇦</span>}</div>
            {/* <br />total bonus granted: {format_seconds(wifi.periods[0].bonus_time_granted)} */}
            <div className={classes.iconWrapper}>
              <div className={classes.iconsLeftAligned}>

                {wifi.enabled || <>
                  <IconButton color="inherit" onClick={() => toggle(true)}>
                    <Icon type="AccessAlarm" />
                  </IconButton>
                  {userInfo.admin && <IconButton color="inherit" onClick={() => toggle(false)}>
                    <Icon type="AlarmOff" />
                  </IconButton>}
                </>}
                {wifi.enabled && <IconButton color="inherit" onClick={() => toggle(true)}>
                  <Icon type="PowerSettingsNew" />
                </IconButton>}
              </div>

            </div>
            <div className={classes.separatorLine}>
              <hr />
            </div>
            <div className={classes.iconWrapper}>
              <div className={classes.iconsLeftAligned}>
                <IconButton color="inherit" onClick={addTime}>
                  <Icon type="Add" />
                </IconButton>
                <IconButton color="inherit" onClick={block}>
                  <Icon type="Block" />
                </IconButton>
              </div>
              <div className={classes.iconsRightAligned}>
                <IconButton className={classes.settings} color="inherit" onClick={settings}>
                  <Icon type="Settings" />
                </IconButton>
                {/* <div onClick={() => toggle(false)}>unmetered</div> */}
              </div>
            </div>
          </div>
        }
      />
      {/* <CardMedia className={classes.media} image={image} title={title} /> */}
    </Card >
  )
}

export default WifiContentEntry
