import React from 'react'
import { Container, Grid } from 'components/shared'
import Entry from './Entry'
import Loader from 'components/layout/Loader'

import { WifiState } from 'types/wifi'
import useStyles from './styles'
import { BUILD_VERSION } from 'utils/version'

type Props = {
  wifis: WifiState | null
}

const WifiContent = ({ wifis }: Props) => {
  const classes = useStyles()

  function renderContent() {
    if (!wifis) {
      return <Loader />
    }

    return (Object.keys(wifis)).sort().map(key =>
      <Grid item key={key} md={4} xs={12}>
        <Entry id={key} wifi={wifis[key]}  />
      </Grid>)
  }

  return (
    <Container navbar>
      <Grid container spacing={2}>
        {renderContent()}
        <div className={classes.versionWrapper}>
          <div className={classes.version}>version {BUILD_VERSION}</div>
        </div>
      </Grid>
    </Container>
  )
}

export default WifiContent
