export default {
  base: '/',
  unsigned: {
    signin: '/sign-in'
  },
  signed: {
    // overview: '/overview',
    wifi: '/wifi',
  }
}
