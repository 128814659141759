import React, { Fragment } from 'react'

import { Drawer, Divider, Hidden, List, ListItem, ListItemText, ListItemIcon, Icon } from 'components/shared'

import useStyles from './styles'
import routes from 'routing/routes'
import useReactRouter from 'use-react-router'
import { useDispatch } from 'store'
import * as userActions from 'store/user/actions'

type Props = {
  mobileOpen: boolean
  toggleMobileSidebar: () => void
}

const SideBar = ({ mobileOpen, toggleMobileSidebar }: Props) => {
  const classes = useStyles()
  const { history } = useReactRouter()
  const dispatch = useDispatch()

  const navigate = (page: string) => {
    history.push(page)
    toggleMobileSidebar()
  }

  function renderList() {
    return (
      <Fragment>
        <Divider />
        <List>
          {/* <ListItem button onClick={() => navigate(routes.signed.overview)} >
            <ListItemIcon>
              <Icon type="Dashboard" />
            </ListItemIcon>
            <ListItemText primary="Overview" />
          </ListItem> */}
          <ListItem button onClick={() => navigate(routes.signed.wifi)} >
            <ListItemIcon>
              <Icon type="Wifi" />
            </ListItemIcon>
            <ListItemText primary="Wifi Overview" />
          </ListItem>
          <ListItem button onClick={() => dispatch(userActions.remove())} >
            <ListItemIcon>
              <Icon type="ExitToApp" />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Fragment >
    )
  }

  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer
          variant="temporary"
          anchor="left"
          classes={{ paper: classes.drawerPaper }}
          open={mobileOpen}
          onClose={toggleMobileSidebar}
          ModalProps={{ keepMounted: true }}
        >
          {renderList()}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer open classes={{ paper: classes.drawerPaper }} variant="permanent">
          {renderList()}
        </Drawer>
      </Hidden>
    </Fragment>
  )
}

export default SideBar
