import axios, { AxiosRequestConfig } from 'axios'
import { UserAPIType } from 'types/user';

// const URL = window.location.hostname === "localhost" ? "http://localhost:8000/user" : "https://scanapi.ullstar.de/user"
const URL = "https://scanapi.ullstar.de/user"

const authHeader = (token: string):AxiosRequestConfig | null => {

  if (!token) {
    console.log('no token!')
    return null
  }
  return {
    headers: { Authorization: `Bearer ${token}` }
  };
}

export function get(token: string): Promise<UserAPIType> | null {
  const config = authHeader(token)
  if (!config) {
    return null
  }

  const p = axios.get(URL, config)
    .then((res) => res.data)
    .catch( e => { if (e?.response?.status == 401) {throw e} else { console.log("User API could not be fetched", e)} } )
  return p
}
